import Vue from 'vue'
import VueRouter from 'vue-router'
import pinia from '@/store'
import { useAppState } from '@/store/AppState'

let routes

if (process.env.VUE_APP_PRODMODE == 'report') routes = [
	{
		path: '/',
		component: () => import('@/views/WebHomeReportIndex'),
		children: [
			{
				path: '/',
				component: () => import('@/components/home/HomeReportIndex'),
			},
			{
				path: '/login',
				component: () => import('@/components/home/HomeLogin'),
			},
		]
	},
	{
		path: '/admin',
		component: () => import('@/views/WebAdminIndex'),
		meta: {
			levelGranted: ['D', 'S', 'A', 'U'],
		},
		children: [
			{
				path: '/admin',
				name: 'dashboard',
				component: () => import('@/components/admin/AdminIndex'),
				meta: {
					levelGranted: ['D', 'S', 'A', 'U'],
				},
			},
			{
				path: '/admin/report',
				name: 'report',
				component: () => import('@/components/admin/AdminReport'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/account',
				name: 'account',
				component: () => import('@/components/admin/AdminAccount'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/auditLog',
				name: 'auditLog',
				component: () => import('@/components/admin/AdminAuditLog'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
		]
	},
	{
		path: '*',
		component: () => import( '@/views/WebNotFound'),
	},
]

else if (process.env.VUE_APP_PRODMODE == 'result') routes = [
	{
		path: '/',
		component: () => import('@/views/WebHomeIndex'),
		children: [
			{
				path: '/',
				component: () => import('@/components/home/HomeIndex'),
			},
		]
	},
	{
		path: '/:id',
		component: () => import('@/views/WebDocView'),
		children: [
			{
				path: '/:id',
				component: () => import('@/components/doc/DocIndex'),
			},
		]
	},
	{
		path: '*',
		component: () => import( '@/views/WebNotFound'),
	},
]

else if (process.env.VUE_APP_PRODMODE == 'home') routes = [
	{
		path: '/',
		component: () => import('@/views/WebHomeIndex'),
		children: [
			{
				path: '/',
				component: () => import('@/components/home/HomeIndex'),
			},
		]
	},
	{
		path: '*',
		component: () => import( '@/views/WebNotFound'),
	},
]

else routes = [
	{
		path: '/',
		component: () => import('@/views/WebHomeReportIndex'),
		children: [
			{
				path: '/',
				component: () => import('@/components/home/HomeReportIndex'),
			},
			{
				path: '/login',
				component: () => import('@/components/home/HomeLogin'),
			},
		]
	},
	{
		path: '/result',
		component: () => import('@/views/WebDocView'),
		children: [
			{
				path: '/result/:id',
				component: () => import('@/components/doc/DocIndex'),
			},
		]
	},
	{
		path: '/admin',
		component: () => import('@/views/WebAdminIndex'),
		meta: {
			levelGranted: ['D', 'S', 'A', 'U'],
		},
		children: [
			{
				path: '/admin',
				name: 'dashboard',
				component: () => import('@/components/admin/AdminIndex'),
				meta: {
					levelGranted: ['D', 'S', 'A', 'U'],
				},
			},
			{
				path: '/admin/report',
				name: 'report',
				component: () => import('@/components/admin/AdminReport'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/account',
				name: 'account',
				component: () => import('@/components/admin/AdminAccount'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
			{
				path: '/admin/auditLog',
				name: 'auditLog',
				component: () => import('@/components/admin/AdminAuditLog'),
				meta: {
					levelGranted: ['D', 'S', 'A'],
				},
			},
		]
	},
	{
		path: '*',
		component: () => import( '@/views/WebNotFound'),
	},
]

Vue.use(VueRouter)

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
})

if (process.env.VUE_APP_PRODMODE == 'report') {
	router.beforeEach(async (to, from, next) => {
		let nextOrDefault = (condt, def) => condt ? next() : next(def)
		let lRoutes = to.matched.filter(record => record.meta.levelGranted)

		if (lRoutes.length > 0) {
			const store = useAppState(pinia)
			await store.checkLogin()

			let isGranted = false

			if (store.level_user) isGranted = lRoutes[0].meta.levelGranted.includes(store.level_user)

			nextOrDefault(isGranted, '/login')
		} else {
			next()
		}
	})
}

export default router
